import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { PlaybackEvent, PlayerAPI, PlayerEventBase, SeekEvent } from 'bitmovin-player'
import { PlayerEvent } from '@/organisms/AngelPlayer'
import { ReactFCC } from '@/types/react'
import { logger } from '@/utils/logging'

type BitAngelPlayerTimeContextValues = {
  currentTime: number
}

const defaultValues = {
  currentTime: 0,
}

const BitAngelPlayerTimeContext = createContext<BitAngelPlayerTimeContextValues>(defaultValues)

interface BitAngelPlayerTimeProviderProps {
  player?: PlayerAPI
}

export const BitAngelPlayerTimeProvider: ReactFCC<BitAngelPlayerTimeProviderProps> = ({ player, children }) => {
  const [currentTime, setCurrentTime] = useState(0)

  const onTimeChange = useCallback(
    (event?: PlayerEventBase) => {
      const time = (event as PlaybackEvent)?.time
      if (time !== undefined && time !== null) {
        setCurrentTime(time)
      }
    },
    [setCurrentTime],
  )

  const onSeek = useCallback(
    (event?: PlayerEventBase) => {
      const time = (event as SeekEvent)?.seekTarget
      if (time !== undefined && time !== null) {
        setCurrentTime(time)
      }
    },
    [setCurrentTime],
  )

  useEffect(() => {
    if (player) {
      try {
        player.on(PlayerEvent.TimeChanged, onTimeChange)
        player.on(PlayerEvent.Seek, onSeek)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        logger().error(`BitAngelPlayerContext - Error adding events from player: ${error?.message}`, { player, error })
      }

      return () => {
        try {
          player.off(PlayerEvent.TimeChanged, onTimeChange)
          player.off(PlayerEvent.Seek, onSeek)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          logger().error(`BitAngelPlayerContext - Error removing events from player: ${error?.message}`, {
            player,
            error,
          })
        }
      }
    }
  }, [player, onTimeChange, onSeek])

  const value = useMemo(() => {
    return {
      currentTime,
    }
  }, [currentTime])

  return <BitAngelPlayerTimeContext.Provider value={value}>{children}</BitAngelPlayerTimeContext.Provider>
}

export const useBitAngelTimeContext = () => {
  return useContext(BitAngelPlayerTimeContext)
}
